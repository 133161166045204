import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

const App = (props) => {
  const [rooms, setRooms] = React.useState([]);
  const [gallons, setGallons] = React.useState(0);
  const [showForm, setShowForm] = React.useState(true);

  const widthRef = React.useRef(0);
  const lenghtRef = React.useRef(0);
  const heightRef = React.useRef(0);
  const windowRef = React.useRef(0);
  const doorRef  = React.useRef(0);
  const paintingCeilingRef = React.useRef(false);

  const calcRoom = () => {
    // TODO measure!
    const DOOR_SIZE = 25;
    const WINDOW_SIZE = 16;

    const w = widthRef.current.value;
    const l = lenghtRef.current.value;
    const h = heightRef.current.value;

    const doors = doorRef.current.value;
    const windows = windowRef.current.value;

    let sqft = (2 * w * h) + (2 * l * h);

    const pc = paintingCeilingRef.current.checked;

    if (pc) {
      sqft += w * l;
    }
    sqft = sqft - (DOOR_SIZE * doors) - (WINDOW_SIZE * windows);

    return sqft;
  }

  const clearForm = () => {
    widthRef.current.value = '';
    lenghtRef.current.value = '';
    heightRef.current.value = '';
    windowRef.current.value = '';
    doorRef.current.value = '';
    paintingCeilingRef.current.checked = false;
  }

  const addRoomToCollection = () => {
    const roomSqFt = calcRoom();
    rooms.push(roomSqFt)
    setRooms(rooms)
    clearForm();
  }

  const handleSubmit = () => {
    const SQ_FT_PER_GALLON = 400;
    addRoomToCollection();
    const total = rooms.reduce((a,c) => a + c);
    setGallons(Math.ceil(total/SQ_FT_PER_GALLON));
    setRooms([]);
    setShowForm(false);
  }

  const resetForm = () => {
    setGallons(0);
    setShowForm(true);
  }

  const Results = () =>
    <div>
      <h2>{gallons} Gallon{gallons > 1 ? 's' : ''}</h2>
        <Button variant="primary"  onClick={resetForm}>
          Back
        </Button>
    </div>

  const PaintForm = () => {
    return (
      <Form>
        <Form.Group controlId="formWidth">
          <Form.Label>Width</Form.Label>
          <InputGroup>
            <Form.Control type="text" placeholder="0 ft." ref={widthRef}/>
            <InputGroup.Append>
              <InputGroup.Text id="width-addon">
                ft.
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formLength">
          <Form.Label>Length</Form.Label>
          <InputGroup>
            <Form.Control type="text" placeholder="0 ft." ref={lenghtRef}/>
            <InputGroup.Append>
              <InputGroup.Text id="width-addon">
                ft.
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formLength">
          <Form.Label>Ceiling Height</Form.Label>
          <InputGroup>
            <Form.Control type="text" placeholder="0 ft." ref={heightRef}/>
            <InputGroup.Append>
              <InputGroup.Text id="width-addon">
                ft.
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formWindows">
          <Form.Label>Windows In The Room</Form.Label>
          <InputGroup>
            <Form.Control type="text" placeholder="0 windows" ref={windowRef}/>
            <InputGroup.Append>
              <InputGroup.Text id="window-addon">
                #
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formDoors">
          <Form.Label>Doors In The Room</Form.Label>
          <InputGroup>
            <Form.Control type="text" placeholder="0 doors" ref={doorRef}/>
            <InputGroup.Append>
              <InputGroup.Text id="door-addon">
                #
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Check
          label="Painting Ceiling?"
          type="checkbox"
          id="painting-ceiling"
          ref={paintingCeilingRef}
          className="text-center"
        />
        <br />
        <Button variant="secondary" block onClick={addRoomToCollection}>
          Add Another Room
        </Button>
        <br />
        <Button variant="primary" size="lg" block onClick={handleSubmit}>
          Calculate
        </Button>
      </Form>
    )
  }

  return (
    <Container>
      <br />
      <h1 className="text-center">How Much Paint Should I Buy?</h1>
      {
        showForm ?
          <PaintForm />
          :
          <Results />
      }
    </Container>
  )
}

export default App;
